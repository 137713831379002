import React from "react";
import { Link } from "gatsby";
import UserLinks from "../UserLinks/UserLinks";
import "./Footer.css";

function Footer({ config }) {
  const { copyright, siteRss: url } = config;
  if (!copyright) {
    return null;
  }
  return (
    <footer className="footer mt-12 py-4 text-sm border-t border-dotted border-gray-500">
      <UserLinks config={config} labeled />
      <div className="notice-container">
        <h4>{copyright}</h4>

        <Link to={url}>
          <button type="button" className="font-light text-orange-600">
            Subscribe
          </button>
        </Link>
      </div>
    </footer>
  );
}

export default Footer;
