import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

export default function Header() {
  return (
    <nav className="flex justify-center m-10">
      <Link to="/">
        <StaticImage
          src="../images/sokicorp-logo-500x500.png"
          alt=""
          className="h-20 w-20 border-2 border-black rounded-sm"
        />
      </Link>
    </nav>
  );
}
