import React from "react";
import { Helmet } from "react-helmet";

import Header from "../components/Header";
import Footer from "../components/Footer/Footer";
import config from "../../data/SiteConfig";
import "./index.css";

export default function MainLayout({ children }) {
  return (
    <>
      <Helmet>
        <meta name="description" content={config.siteDescription} />
        <html lang="en" />
      </Helmet>

      <div className="font-sans font-light">
        <Header />
        <div className="w-full max-w-2xl mx-auto px-6 pb-12">
          <main>{children}</main>
          <Footer config={config} />
        </div>
      </div>
    </>
  );
}
